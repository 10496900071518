import { IEmailLink } from '../types/global.types';

export const isBrowser = typeof window !== 'undefined';

export const timeout = (time: number) => {
	return new Promise((resolve) => setTimeout(resolve, time));
};

export const htmlRaw = (content: string) => {
	return {__html: content};
};

const replaceNewLineMarker = (content) => {
	const splitOnNewLineMarkers = content.split('#/#break#\\#');
	const encodedBodyWithNewLineBreaks = splitOnNewLineMarkers.map(str => `${encodeURIComponent(str)}%0D%0A%0D%0A`);
	
	return encodedBodyWithNewLineBreaks.join('');
};

export const getFormattedEmailLink = (data: IEmailLink) => {
	const formattedBody = replaceNewLineMarker(data.body);

	return `mailto:${data.email}?subject=${encodeURIComponent(data.subject)}&body=${formattedBody}`;
};