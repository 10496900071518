import StoryblokClient, { Richtext } from 'storyblok-js-client';

const Storyblok = new StoryblokClient({
	accessToken: process.env.STORY_BLOK_TOKEN,
});

Storyblok.setComponentResolver((component, blok) => {
	switch(component) {
	case 'nameWithTitleForRTE':
		return `
			<div class='half-width'>
				<span class='faded-caption'>${blok.title}</span>
				<b>${blok.name}</b>
			</div>
		`;
	}
});

export const richTextResolver = (body: Richtext) => {
	const html = Storyblok.richTextResolver.render(body);
	return html;
};