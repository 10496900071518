import { Link } from 'gatsby';
import React from 'react';
import Logo from '../../svg/logo';
import { ILink } from '../../types/global.types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const A1MainHeader = ({ emailLink }: {emailLink: ILink}) => {

	return (
		<header className='a1-main-header'>
			<div className='a1-main-header__inner-wrapper'>
				<Link to='/'><Logo className='a1-main-header__logo' /></Link>
				<AnchorLink to={`/#${emailLink.link.anchor}`} className='a1-main-header__link' stripHash >{emailLink.caption}</AnchorLink>
			</div>
		</header>
	);
};

export default A1MainHeader;
