import React from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import A1MainHeader from '../a1-main-header/a1-main-header';
import A2Footer from '../a2-footer/a2-footer';
import B1Hero from '../b1-hero/b1-hero';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, headerData, footerData, heroData, className }) => {

	useEffect(() => {
		window.addEventListener('CookieInformationConsentGiven', () => {
			if(!CookieInformation.getConsentGivenFor('cookie_cat_functional')
			&& !CookieInformation.getConsentGivenFor('cookie_cat_statistic')
			&& !CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
				window[`ga-disable-${process.env.GA_TRACKING_ID}`] = true;
				return;
			}

			// if (CookieInformation.getConsentGivenFor('cookie_cat_functional')) {}
			if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
				gtag('consent', 'update', {
					'analytics_storage': 'granted',
				});
			}
			if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
				gtag('consent', 'update', {
					'ad_storage': 'granted',
				});
			}
		}, false);
	}, []);

	return (
		<div className={`root-wrapper ${className}`}>
			<A1MainHeader emailLink={headerData} />
			<main className='main'>
				{heroData && <B1Hero {...heroData} />}
				{children}
			</main>
			<A2Footer {...footerData} />
		</div>
	);
};

export default Layout;