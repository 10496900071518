import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useEffect } from 'react';
import { timeout } from '../utils/utils';
 
type MetaProps = {
	title?:string;
	language?:string;
	description?:string;
	keywords?:string[];
	ogTitle?:string;
	ogDescription?:string;
	ogImage?:string;
	ogUrl?:string;
	ogType?:string;
}

const Meta = ({
	language,
	title,
	description,
	ogTitle,
	ogDescription,
	ogImage,
}: MetaProps) => {
	const { site } = useStaticQuery(
		graphql`
				query {
					site {
						siteMetadata {
							lang
							title
							description
						}
					}
				}
			`,
	);

	const lang = language || site.siteMetadata.lang || 'da';
	const metaDescription = description || site.siteMetadata.description;
	const facebookTitle = ogTitle || title;
	const facebookDescription = ogDescription || metaDescription; 
	const defaultTitle = site.siteMetadata.title;
	const [ preloading, setPreloading ] = useState(true);

	const preloadHandler = async() => {
		await timeout(500);
		setPreloading(false);
	};

	useEffect(() => {
		preloadHandler();//we want to prevent initial animation trigger on some elements like .button (check global.scss)
	}, []);
 
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			bodyAttributes={{
				class: `body ${preloading ? 'body--preload' : ''}`,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: facebookTitle,
				},
				{
					property: 'og:description',
					content: facebookDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: ogImage,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: '',
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
			]}
		>
			<link rel="stylesheet" href="https://use.typekit.net/tqb1uxl.css" />
			<script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js" data-culture="DA" type="text/javascript"></script>
		</Helmet>
	);
};

export default Meta;