import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { IHero } from '../../types/global.types';
import Picture from '../picture/picture';

const B1Hero = ({ text, image, video }: IHero) => {
	const textRef = useRef(null);
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1094, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 957, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 768, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 584, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 1024, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 554, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 554, width: 480, media: '(max-width: 480px)' },
	];

	useEffect(() => {
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			gsap.fromTo(textRef.current, {
				y: 0,
			}, {
				y: -100,
				scrollTrigger: {
					end:  window.innerHeight,
					scrub: 1,
					invalidateOnRefresh: true,
				},
			});
		}});

		return () => gsap.killTweensOf(textRef.current);
	}, []);

	return (
		<section className='b1-hero'>
			{video 
				?
				<video className='b1-hero__video' autoPlay playsInline loop muted poster={image?.filename}>
					<source src={video.filename} type="video/mp4" />
				</video>
				:
				<Picture image={image} queries={queries} />
			}
			<div className='b1-hero__content box-wide'>
				<h1 ref={textRef} className='b1-hero__content__text headline-3'>{text}</h1>
			</div>
		</section>
	);
};

export default B1Hero;
