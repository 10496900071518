import React from 'react';
import { IFooter } from '../../types/global.types';
import { getFormattedEmailLink } from '../../utils/utils';

const A2Footer = ({ contactCaption, contactDetailsList, email, termsCaption, termsLinksList, logos }: IFooter) => {

	return (
		<footer className='a2-footer'>
			<div className='box-wide a2-footer__top'>
				<div className='a2-footer__info-section a2-footer__info-section--contact'>
					<h3 className='a2-footer__info-section__headline sub-1'>{contactCaption}</h3>
					<ul className='a2-footer__info-section__item-list'>
						{contactDetailsList.map(cd => 
							<li key={cd.location} className='a2-footer__info-section__item'>
								<a href={`tel:${cd.phoneNumber}`}>{cd.phoneNumber}</a>
								<span className='a2-footer__info-section__item__location'>{cd.location}</span>
							</li>,
						)}
						<li className='a2-footer__info-section__item'>
							<a href={getFormattedEmailLink(email[0])}>{email[0].caption}</a>
						</li>
					</ul>
				</div>
				<div className='a2-footer__info-section a2-footer__info-section--terms'>
					<h3 className='a2-footer__info-section__headline sub-1'>{termsCaption}</h3>
					<ul className='a2-footer__info-section__item-list'>
						{termsLinksList.map(tl => 
							<li key={tl.caption} className='a2-footer__info-section__item'>
								<a href={`/${tl.link.cached_url}`}>{tl.caption}</a>
							</li>,
						)}
					</ul>
				</div>
			</div>
			<div className='a2-footer__bottom'>
				<div className='box-wide a2-footer__bottom__inner'>
					{logos.map(l => <div key={l.id} className='a2-footer__logo-wrapper'><img className='a2-footer__logo' src={l.filename} /></div>)}
				</div>
			</div>
		</footer>
	);
};

export default A2Footer;